import React from 'react';
import picture40 from '../toadtracker_resources/pod_page/mapping/Picture40.png';
import picture41 from '../toadtracker_resources/pod_page/mapping/Picture41.png';
import picture42 from '../toadtracker_resources/pod_page/mapping/Picture42.png';
import picture43 from '../toadtracker_resources/pod_page/mapping/Picture43.png';
import picture44 from '../toadtracker_resources/pod_page/mapping/Picture44.png';
import picture46 from '../toadtracker_resources/pod_page/mapping/Picture46.png';
import picture15 from '../toadtracker_resources/pod_page/mapping/Picture15.png';
import picture37 from '../toadtracker_resources/pod_page/mapping/Picture37.png';
import picture38 from '../toadtracker_resources/pod_page/mapping/Picture38.png';
import picture39 from '../toadtracker_resources/pod_page/mapping/Picture39.png';
import mapping from '../toadtracker_resources/pod_page/mapping/mapping.PNG';

const imageSizeClass = "max-h-72 w-auto object-contain mx-auto rounded-lg shadow-lg hover:scale-105 transform transition-all duration-300 mb-4"; // Standardized image size

const MappingHOW = () => {
    return (
        <>
            <div 
                className="hero bg-gradient-to-r from-yellow-50 to-yellow-200 py-12 lg:py-24 relative" 
                id="mapping-how"
            >                
                <div 
                    className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" 
                >
                    <div 
                        id="mapping-how" 
                        className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left"
                    >
                        <div 
                            className="lg:w-1/2 flex flex-col justify-center" 
                        >
                            <h1 
                                className="mb-5 md:text-6xl text-4xl font-extrabold text-yellow-900 leading-tight animate-fade-in"
                            >
                                How ToadTracker Maps Cane Toad Locations
                            </h1>
                            <div 
                                className="text-xl font-semibold tracking-tight mb-5 text-gray-600 lg:text-lg animate-slide-up"
                            >
                                The ToadTracker Pod integrates several sophisticated technologies to accurately map cane toad locations based on audio source localization. Here’s the technical workflow and analysis.
                            </div>
                        </div>
                        <div 
                            id="mapping-img"  
                            className="flex lg:justify-end w-full lg:w-1/2 relative" 
                        >
                            <img 
                                alt="Mapping Process" 
                                className="rounded-t float-right duration-1000 w-50% drop-shadow-lg hover:scale-105 transform transition-all ease-out ellipse-frame" 
                                src={mapping} 
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div id="details" className="container mx-auto px-4 lg:px-20 py-16 lg:py-24">
                <h2 className="font-bold text-yellow-800 uppercase text-3xl mb-6 text-center">Mapping Workflow</h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                    The ToadTracker's mapping functionality is built on precise localization data and advanced algorithms that transform this data into meaningful maps. The process involves GPS accuracy testing, error analysis, and angular deviation studies to ensure high accuracy in the final mapping output.
                </p>
                <div className="flex justify-center mb-8">
                    <img src={picture15} alt="Mapping Workflow Diagram" className="max-h-288 w-auto object-contain mx-auto rounded-lg shadow-lg hover:scale-105 transform transition-all duration-300 mb-4" />
                </div>

                <h2 className="font-bold text-yellow-800 uppercase text-3xl mb-6 text-center">GPS and Localization Accuracy</h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                    The accuracy of the GPS system and the localization angles are critical in ensuring that cane toad locations are mapped correctly. Below are the sensitivity analysis graphs and error distribution visualizations which were crucial in validating our mapping approach.
                </p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
                    <div className="flex flex-col justify-center items-center">
                        <img src={picture38} alt="1m Sensitivity Analysis" className={imageSizeClass} />
                        <p className="text-sm text-gray-600 text-center mt-4">Sensitivity analysis at 1m shows the confidence intervals for detecting cane toad locations within a small radius.</p>
                    </div>
                    
                    <div className="flex flex-col justify-center items-center">
                        <img src={picture39} alt="5m Sensitivity Analysis" className={imageSizeClass} />
                        <p className="text-sm text-gray-600 text-center mt-4">Sensitivity analysis at 5m extends the confidence intervals, indicating potential errors at greater distances.</p>
                    </div>

                    <div className="flex flex-col justify-center items-center">
                        <img src={picture40} alt="Computed Elevation Angle" className={imageSizeClass} />
                        <p className="text-sm text-gray-600 text-center mt-4">Calibration of computed elevation angles versus actual angles helps in refining the accuracy of the localization system.</p>
                    </div>
                    
                    <div className="flex flex-col justify-center items-center">
                        <img src={picture41} alt="Angular Deviation" className={imageSizeClass} />
                        <p className="text-sm text-gray-600 text-center mt-4">Angular deviation analysis shows how the system performs under different rig angles, helping in adjusting the algorithms for better accuracy.</p>
                    </div>
                </div>

                <h2 className="font-bold text-yellow-800 uppercase text-3xl mb-6 text-center">Error and Accuracy Analysis</h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                    In the mapping system, various sources of error are considered, including GPS error, microphone array error, and IMU+LIDAR error. These analyses ensure that the final mapping output is within acceptable error margins.
                </p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
                    <div className="flex flex-col justify-center items-center">
                        <img src={picture42} alt="Localization Error Analysis" className={imageSizeClass} />
                        <p className="text-sm text-gray-600 text-center mt-4">Localization error analysis visualizes how different errors combine to affect the final position estimate of cane toad locations.</p>
                    </div>
                    
                    <div className="flex flex-col justify-center items-center">
                        <img src={picture43} alt="GPS Stationary Accuracy Test" className={imageSizeClass} />
                        <p className="text-sm text-gray-600 text-center mt-4">Stationary GPS accuracy test results show the baseline error when the system is stationary, helping to calibrate the system for movement-based error compensation.</p>
                    </div>

                    <div className="flex flex-col justify-center items-center">
                        <img src={picture44} alt="Overall Error Distribution" className={imageSizeClass} />
                        <p className="text-sm text-gray-600 text-center mt-4">Overall error distribution graph aggregates different sources of error, providing a comprehensive view of system performance.</p>
                    </div>
                    
                    <div className="flex flex-col justify-center items-center">
                        <img src={picture46} alt="Mapping Result with Cane Toad Distribution" className={imageSizeClass} />
                        <p className="text-sm text-gray-600 text-center mt-4">Final mapping output showing the distribution of cane toads overlaid on a satellite map, validated through various error and accuracy tests.</p>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 lg:px-20 text-center py-16 lg:py-24">
                <h2 className="font-bold text-yellow-800 uppercase text-3xl mb-6 text-center">Explanation</h2>
                <ul className="text-xl text-gray-700 leading-relaxed mb-4 list-disc list-inside">
                    <li>The mapping process uses satellite data to precisely locate identified cane toads.</li>
                    <li>Error analysis and accuracy testing were crucial in improving the mapping process.</li>
                    <li>The final system achieved an angular localization accuracy of ±1.83 meters.</li>
                    <li>The mapping system has a range of up to 22.5 meters.</li>
                </ul>
                <h2 className="font-bold text-yellow-800 uppercase text-3xl mb-6 text-center">TLDR</h2>
                <p className="text-xl text-gray-700 leading-relaxed">
                    The mapping system locates cane toads with ±1.83m accuracy and a range of 22.5m using satellite data.
                </p>
            </div>

        </>
    );
}

export default MappingHOW;
