import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import donationImg from '../toadtracker_resources/support_page/doggyu.jpg'; // Adding the donation image

const Funding = () => {
    useDocTitle('ToadTracker | Funding & Support');

    return (
        <>
            <div className="relative bg-white py-12 lg:py-20" id="funding">
                <div>
                    <NavBar />
                    <div className="container mx-auto px-4 lg:px-20 text-center lg:text-left">
                        <div className="flex flex-col lg:flex-row items-center py-8 lg:py-16">
                            <div className="lg:w-1/2 flex flex-col justify-center">
                                <h1 className="text-5xl lg:text-6xl font-extrabold text-green-800 mb-6 animate-fade-in">
                                    Your Support Makes a Difference!
                                </h1>
                                <p className="text-lg text-gray-700 leading-relaxed mb-6 animate-slide-up">
                                    Help us protect Australia’s wildlife from the invasive cane toad. With your support, we've raised enough money to start prototyping, but there’s still work to do. Let’s drive this mission forward together!
                                </p>
                                <a 
                                    href="https://www.gofundme.com/f/v5fek-cane-toads-save-australias-wildlife?qid=f4c2cce6fdd6d4ca40663123c021de6b" 
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-white bg-green-600 hover:bg-green-700 transition-all duration-300 px-8 py-4 text-lg shadow-lg rounded-lg inline-flex items-center justify-center shine-effect transform hover:-translate-y-1"
                                >
                                    Support Us
                                    <svg className="w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </a>
                            </div>
                            <div className="lg:w-1/2 mt-8 lg:mt-0 lg:ml-12">
                                <div className="relative rounded-full shadow-lg transform hover:scale-105 transition-transform duration-500">
                                    <img 
                                        src={donationImg} 
                                        alt="Donation Impact" 
                                        className="rounded-full object-cover"
                                    />
                                    <div className="absolute inset-0 bg-green-800 opacity-20 rounded-full"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 lg:px-20 py-12 lg:py-16">
                <h2 className="font-bold text-green-800 uppercase text-3xl mb-8 text-center">How Your Support Helps</h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                    <div className="bg-gray-100 p-8 rounded-lg shadow-lg">
                        <h3 className="font-bold text-xl text-green-700 mb-4">Development of the Pod</h3>
                        <p className="text-gray-700">Your donations are directly helping us develop, build and now refine the drone-mounted pods that will enhance cane toad detection.</p>
                    </div>
                    <div className="bg-gray-100 p-8 rounded-lg shadow-lg">
                        <h3 className="font-bold text-xl text-green-700 mb-4">Field Testing</h3>
                        <p className="text-gray-700">We’re conducting rigorous field tests to ensure that our pods work effectively in real-world conditions, thanks to your contributions.</p>
                    </div>
                    <div className="bg-gray-100 p-8 rounded-lg shadow-lg">
                        <h3 className="font-bold text-xl text-green-700 mb-4">Educational Campaigns</h3>
                        <p className="text-gray-700">We are dedicated to promoting preventive measures within communities alongside Landcare.</p>
                    </div>
                    <div className="bg-gray-100 p-8 rounded-lg shadow-lg">
                        <h3 className="font-bold text-xl text-green-700 mb-4">CNC Machining, 3D printing and GPU processing</h3>
                        <p className="text-gray-700">Your funds go directly to use of our CNC machine, 3D printers, and processing computers used on the drone pods and to build them.</p>
                    </div>
                </div>
                <div className="text-center mt-8">
                    <h3 className="text-3xl font-bold text-green-800 mb-6 animate-pulse">We've Raised Enough to Start Prototyping!</h3>
                    <p className="text-lg text-gray-700 mb-6">Thanks to the generosity of our supporters, we’ve made significant progress, but we still have a long way to go. Every bit of help, whether it is financial, or your time and skills, helps us get closer to a cane toad-free Australia, or at least one where cane toads have stopped spreading so rapidly, allowing our native wildlife time to properly adapt.</p>
                    <a 
                        href="https://www.gofundme.com/f/v5fek-cane-toads-save-australias-wildlife?qid=f4c2cce6fdd6d4ca40663123c021de6b" 
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white bg-green-700 hover:bg-green-800 transition-all duration-300 px-8 py-4 text-lg shadow-lg rounded-lg inline-flex items-center justify-center shine-effect transform hover:-translate-y-1"
                    >
                        Support Us
                    </a>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default Funding;
