import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleMouseEnter = () => {
        setDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        setDropdownOpen(false);
    };

    return (
        <div className="flex space-x-4 items-center"> {/* Flex container for horizontal layout */}
            <HashLink
                scroll={handleScrollToTop}
                className="px-4 font-extrabold text-gray-500 hover:text-green-900"
                smooth
                to="/#about"
                onClick={handleScrollToTop}
            >
                Home
            </HashLink>
            <HashLink
                className="px-4 font-extrabold text-gray-500 hover:text-green-900"
                smooth
                to="/mapping"
                onClick={handleScrollToTop}
            >
                Live Mapping
            </HashLink>
            <HashLink
                className="px-4 font-extrabold text-gray-500 hover:text-green-900"
                smooth
                to="/pod"
                onClick={handleScrollToTop}
            >
                Pod
            </HashLink>

            {/* Dropdown for How it Works */}
            <div
                className="relative"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <HashLink
                    className="px-4 font-extrabold text-gray-500 hover:text-green-900"
                    smooth
                    to="/howitworks"
                    onClick={handleScrollToTop}
                >
                    How it Works
                </HashLink>

                {/* Dropdown content */}
                {dropdownOpen && (
                    <div
                        className="absolute left-0 mt-0 py-2 w-48 bg-white border rounded-lg shadow-lg"
                        style={{ top: '100%' }} // Stick dropdown directly below the link
                    >
                        <HashLink
                            className="block px-4 py-2 font-extrabold text-gray-500 hover:bg-green-500 hover:text-white"
                            smooth
                            to="/howitworks#recognition-how"
                            onClick={handleScrollToTop}
                        >
                            Identification
                        </HashLink>
                        <HashLink
                            className="block px-4 py-2 font-extrabold text-gray-500 hover:bg-green-500 hover:text-white"
                            smooth
                            to="/howitworks#localisation-how"
                            onClick={handleScrollToTop}
                        >
                            Localization
                        </HashLink>
                        <HashLink
                            className="block px-4 py-2 font-extrabold text-gray-500 hover:bg-green-500 hover:text-white"
                            smooth
                            to="/howitworks#mapping-how"
                            onClick={handleScrollToTop}
                        >
                            Mapping
                        </HashLink>
                    </div>
                )}
            </div>

            <HashLink
                className="px-4 font-extrabold text-gray-500 hover:text-green-900"
                smooth
                to="/support"
                onClick={handleScrollToTop}
            >
                Support us
            </HashLink>
            <HashLink
                className="text-white bg-gradient-to-r from-green-600 to-green-900 shine-effect transform transition-all duration-300 ease-in-out inline-flex items-center justify-center w-auto px-8 py-3 shadow-2xl rounded-2xl hover:scale-105 hover:shadow-green-500/50"
                smooth
                to="/contact"
                onClick={handleScrollToTop}
            >
                <span className="mr-2">💬</span> Get in Touch
            </HashLink>
        </div>
    );
};

export default NavLinks;
