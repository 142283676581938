import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Sound from '../components/Sound';
import Why from '../components/Why';

import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';

const Home = () => {
    return (
        <>
            <Hero />
            <Why />
            <Sound />
            <Intro />
            <Portfolio />
            <Clients />
            <Cta/>
            <Footer />
        </>
    )
}

export default Home;

