import React from 'react';

const Why = () => {
    return (
        <div className="bg-gradient-to-r from-green-50 to-green-200 py-12 lg:py-5" id="sound">
            <div className="container mx-auto px-4 text-center">
                <h2 className="text-4xl font-extrabold text-green-800 mb-8"></h2>
                <h2 className="text-4xl font-extrabold text-green-800 mb-8">
                    Why Use a Drone-Mountable Pod?
                </h2>
                <p className="text-2xl font-bold text-gray-700 leading-relaxed">
                    Cane toads are pushing Australia's wildlife to the brink—our native species need your help now. 
                    Toxic cane toads devastate 90% of Australian wildlife within a few months of arrival. 
                    Their rapid breeding cycles and movements demand a fast-moving, intelligent solution.
                </p>
                <p className="text-2xl font-bold text-gray-700 leading-relaxed mt-6">
                    Utilizing the latest in AI identification and drone technology, the ToadTracker Pod is detect and processes cane toad movements in even the most remote areas. 
                    Precise localization of cane toad populations and the invasion front enables targeted interventions.
                    The system maps isolated water bodies and areas, anticipating future breeding and movements.
                </p>
                <p className="text-2xl font-extrabold text-gray-700 leading-relaxed mt-6">
                    Saving out wildlife cannot wait! Get in touch today and let's tackle the cane toad invasion together.
                </p>
            </div>
        </div>
    );
}

export default Why;
