import React from 'react';
import tabloid1 from '../toadtracker_resources/main_page/tabloid1.jpg';
import tabloid2 from '../toadtracker_resources/main_page/tabloid2.PNG';
import tabloid3 from '../toadtracker_resources/main_page/tabloid3.png';
import tabloid4 from '../toadtracker_resources/main_page/tabloid4.png';
import tabloid5 from '../toadtracker_resources/main_page/tabloid5.jpg';

const Intro = () => {
    return (
        <div className="m-auto max-w-7xl p-4 md:p-12 h-5/6 space-y-6"> {/* Stacked vertically, spaced evenly */}
            {/* Spread Calling Section */}
            <div className="bg-white rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-2xl flex flex-col lg:flex-row items-center">
                <img alt="Cane Toad Spread Map" className="w-full lg:w-1/2 rounded-l" src={tabloid5} />
                <div className="py-4 px-6 lg:w-1/2">
                    <h3 className="text-2xl text-green-900 font-bold">Projected Cane Toad Spread</h3>
                    <ul className="my-3 text-lg font-semibold list-disc list-inside">
                        <li>Current cane toad population and 2030 projections.</li>
                        <li>Rapid expansion of the species.</li>
                        <li>Urgent need for control measures.</li>
                        <li>Protects Australia's wildlife from further harm.</li>
                    </ul>
                </div>
            </div>

            {/* Problem Section */}
            <div className="bg-white rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-2xl flex flex-col lg:flex-row items-center"> {/* Flex for image + text side-by-side */}
                <img alt="Cane Toad Problem" className="w-full lg:w-1/2 rounded-l" src={tabloid1} />
                <div className="py-4 px-6 lg:w-1/2">
                    <h3 className="text-2xl text-green-900 font-bold">Cane Toad Invasion - A National Problem</h3>
                    <ul className="my-3 text-lg font-semibold list-disc list-inside">
                        <li>Introduced to north Queensland in 1935.</li>
                        <li>Invasive species that spreads quickly.</li>
                        <li>Toxic at all life stages.</li>
                        <li>Significant biological danger.</li>
                        <li>Devastates 90% of native predators within months of arrival.</li>
                    </ul>
                </div>
            </div>

            {/* Solution Section */}
            <div className="bg-white rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-2xl flex flex-col lg:flex-row items-center">
                <img alt="Pod Design" className="w-full lg:w-1/2 rounded-l" src={tabloid2} />
                <div className="py-4 px-6 lg:w-1/2">
                    <h3 className="text-2xl text-green-900 font-bold">Innovative Solution - Drone Pod</h3>
                    <ul className="my-3 text-lg font-semibold list-disc list-inside">
                        <li>Drone-mounted cane toad mapping pod.</li>
                        <li>Helps control cane toad populations.</li>
                        <li>Uses on-board AI with 6-microphone array.</li>
                        <li>Accurately identifies cane toad calls.</li>
                        <li>Targets intervention efforts efficiently.</li>
                    </ul>
                </div>
            </div>

            {/* AI Features Section */}
            <div className="bg-white rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-2xl flex flex-col lg:flex-row items-center">
                <img alt="Spectrogram Analysis" className="w-full lg:w-1/2 rounded-l" src={tabloid3} />
                <div className="py-4 px-6 lg:w-1/2">
                    <h3 className="text-2xl text-green-900 font-bold">Advanced Sound Recognition AI</h3>
                    <ul className="my-3 text-lg font-semibold list-disc list-inside">
                        <li>91% identification accuracy.</li>
                        <li>Active noise cancellation for propeller sounds.</li>
                        <li>Uses augmented sound samples for training.</li>
                        <li>Combines CNN and RNN models.</li>
                        <li>Performs well in noisy environments.</li>
                    </ul>
                </div>
            </div>

            {/* Mapping Section */}
            <div className="bg-white rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-2xl flex flex-col lg:flex-row items-center">
                <img alt="Real-Time Mapping" className="w-full lg:w-1/2 rounded-l" src={tabloid4} />
                <div className="py-4 px-6 lg:w-1/2">
                    <h3 className="text-2xl text-green-900 font-bold">Accurate Real-Time Mapping</h3>
                    <ul className="my-3 text-lg font-semibold list-disc list-inside">
                        <li>Real-time cane toad hotspot mapping.</li>
                        <li>Live cane toad invasion boundary.</li>
                        <li>Integrates sound data with GPS and gyro.</li>
                        <li>Ensures precise geographic positioning.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Intro;
