import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';

const Contact = () => {
    useDocTitle('Toad Tracker | Send us a message');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();

        const mailtoLink = `mailto:contact@toadtracker.com.au?subject=New enquiry from ${firstName}&body=Name: ${firstName} ${lastName}%0AEmail: ${email}%0APhone: ${phone}%0AMessage: ${message}`;
        
        window.location.href = mailtoLink;
    };

    return (
        <>
            <NavBar />
            <div id="contact" className="flex justify-center items-start w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row">
                    <form onSubmit={sendEmail} className="lg:w-7/12 bg-white p-8 my-4 lg:my-0 md:px-12 rounded-2xl shadow-2xl lg:mr-8">
                        <div className="flex">
                            <h1 className="font-bold text-center lg:text-left text-green-900 uppercase text-4xl">Send us a message</h1>
                        </div>
                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            <div>
                                <input
                                    name="first_name"
                                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="text"
                                    placeholder="First Name*"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </div>
                            <div>
                                <input
                                    name="last_name"
                                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="text"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </div>
                            <div>
                                <input
                                    name="email"
                                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="email"
                                    placeholder="Email*"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div>
                                <input
                                    name="phone_number"
                                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="text"
                                    placeholder="Phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="my-4">
                            <textarea
                                name="message"
                                placeholder="Message*"
                                className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                        </div>
                        <div className="my-2 w-full lg:w-1/2">
                            <button type="submit" id="submitBtn" className="uppercase text-sm font-bold tracking-wide bg-green-500 hover:bg-green-700 text-white p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline">
                                Send Message
                            </button>
                        </div>
                    </form>
                    <div className="w-full lg:w-5/12 px-8 py-6 bg-green-900 rounded-2xl text-white">
                        <div className="flex flex-col">
                            <div className="flex my-4">
                                <i className="fas fa-map-marker-alt pt-2 pr-2" />
                                <div className="flex flex-col">
                                    <h2 className="text-2xl">Locations</h2>
                                    <p className="text-gray-400">Sunshine Coast QLD & Chadstone VIC</p>
                                </div>
                            </div>
                            <div className="flex my-4">
                                <i className="fas fa-phone-alt pt-2 pr-2" />
                                <div className="flex flex-col">
                                    <h2 className="text-2xl">Call Us</h2>
                                    <p className="text-gray-400">Tel: 61431877207</p>
                                    <div className="mt-5">
                                        <h2 className="text-2xl">E-mail us</h2>
                                        <p className="text-gray-400">contact@toadtracker.com.au</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex mt-4">
                                <a href="https://www.facebook.com/toadtracker" target="_blank" rel="noreferrer" className="rounded-full flex justify-center bg-white h-8 text-green-900 w-8 mx-1 text-center pt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path></svg>
                                </a>
                                <a href="https://www.linkedin.com/company/toad-tracker/" target="_blank" rel="noreferrer" className="rounded-full flex justify-center bg-white h-8 text-green-900 w-8 mx-1 text-center pt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><circle cx="4.983" cy="5.009" r="2.188"></circle><path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Contact;
