import React, { useState, useRef, useEffect } from 'react';
import caneToadSound from '../toadtracker_resources/main_page/cane_toad_audio.mp3';
import spectrogramImg from '../toadtracker_resources/main_page/cane_toad_audio_spectrogram.PNG';

const Sound = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const lineRef = useRef(null);
    const containerRef = useRef(null);
    const animationFrameRef = useRef(null);

    const togglePlayPause = () => {
        const audio = audioRef.current;
        if (isPlaying) {
            audio.pause();
            cancelAnimationFrame(animationFrameRef.current);
        } else {
            audio.play();
            animationFrameRef.current = requestAnimationFrame(updateLinePosition);
        }
        setIsPlaying(!isPlaying);
    };

    const updateLinePosition = () => {
        const audio = audioRef.current;
        const line = lineRef.current;
        const container = containerRef.current;

        if (audio && line && container) {
            const duration = audio.duration;
            const currentTime = audio.currentTime;
            const containerWidth = container.offsetWidth;
            const percentagePlayed = currentTime / duration;
            const newLeft = percentagePlayed * containerWidth;

            line.style.left = `${newLeft}px`;
        }

        if (audio && !audio.paused) {
            animationFrameRef.current = requestAnimationFrame(updateLinePosition);
        }
    };

    useEffect(() => {
        return () => {
            cancelAnimationFrame(animationFrameRef.current);
        };
    }, []);

    return (
        <div className="bg-gradient-to-r from-green-50 to-green-200 py-12 lg:py-5" id="sound">
            <div className="container mx-auto px-4 lg:px-20 text-center lg:text-left">
                <div className="flex flex-col items-center py-12 lg:py-20">
                    <h2 className="text-4xl font-extrabold text-green-800 mb-6 text-center">Want to hear what a cane toad sounds like? Listen.</h2>
                    <p className="text-xl text-gray-700 leading-relaxed mb-6 text-center lg:max-w-6xl lg:mx-auto">
                        Cane toads have a distinctive call that can be heard from far away, making them one of the most identifiable invasive species. Listen to a sound clip that has been processed by our drone pod in Central QLD.
                    </p>
                    <div className="relative" ref={containerRef} style={{ width: '100%', maxWidth: '1200px' }}>
                        <img 
                            src={spectrogramImg} 
                            alt="Spectrogram of Cane Toad Call" 
                            className="rounded-lg object-cover w-full"
                            style={{ maxHeight: '500px' }} // Adjust the maxHeight as needed
                        />
                        <div 
                            ref={lineRef} 
                            className="absolute top-0 h-full bg-red-500" 
                            style={{ width: '2px', left: '0px' }} 
                        />
                    </div>
                    <div className="mt-8">
                        <button 
                            onClick={togglePlayPause} 
                            className="text-white bg-gradient-to-r from-blue-800 to-blue-600 hover:scale-105 transform transition-all duration-300 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 shine-effect"
                            >
                            {isPlaying ? (
                                <svg className="w-8 h-8 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M4 3a1 1 0 011-1h2a1 1 0 011 1v14a1 1 0 01-1 1H5a1 1 0 01-1-1V3zm8-1a1 1 0 011 1v14a1 1 0 01-1 1h-2a1 1 0 01-1-1V3a1 1 0 011-1h2z" clipRule="evenodd" />
                                </svg>
                            ) : (
                                <svg className="w-8 h-8 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M7.629 4.307a1 1 0 011.448-.38l8 5a1 1 0 010 1.746l-8 5A1 1 0 017 14.25V5.75a1 1 0 01.629-.943z" clipRule="evenodd" />
                                </svg>
                            )}
                            {isPlaying ? "Pause" : "Listen"}
                        </button>
                        <audio ref={audioRef} id="cane-toad-audio">
                            <source src={caneToadSound} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sound;
