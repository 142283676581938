import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { MapContainer, TileLayer, Marker, Popup, LayersControl, useMap, Polygon, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useDocTitle } from '../components/CustomHook';
import 'leaflet.markercluster';
import 'leaflet.heat';
import './Mapping.css'; // Import the Mapping.css file here
import * as turf from '@turf/turf';
import { ScaleControl } from 'react-leaflet';
import landMassGeoJSON from './ne_110m_land.json';  // Ensure you have the correct path
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';

const FullscreenControl = () => {
    const map = useMap();
    useEffect(() => {
        L.control.fullscreen({ position: 'topright' }).addTo(map);
    }, [map]);
    return null;
};

// Define the custom marker icon
const mapMarkerIcon = new L.Icon({
    iconUrl: require('../toadtracker_resources/mapping_page/map_marker.png'), // Path to your custom marker
    iconSize: [35, 35], // Adjust size as needed
    iconAnchor: [17.5, 35], // Position the anchor at the bottom center
    popupAnchor: [0, -35], // Adjust the popup position relative to the marker
});

const GeocoderControl = () => {
    const map = useMap();
    useEffect(() => {
        const geocoder = L.Control.geocoder({
            defaultMarkGeocode: false // Disable the default marker
        }).on('markgeocode', function(e) {
            const latlng = e.geocode.center;
            L.marker(latlng, { icon: mapMarkerIcon }) // Use the custom mapMarkerIcon
                .addTo(map)
                .bindPopup(e.geocode.name)
                .openPopup();
            map.setView(latlng, map.getZoom());
        }).addTo(map);
    }, [map]);

    return null;
};

const { BaseLayer, Overlay } = LayersControl;

const Mapping = () => {
    useDocTitle('ToadTracker | Live Cane Toad Mapping');
    const [locations, setLocations] = useState([]);
    const [boundary, setBoundary] = useState(null);

    useEffect(() => {
        const baseUrl = window.location.hostname === 'localhost' ? 'http://localhost:5000' : '';
        fetch(`${baseUrl}/api/localised`)
            .then(res => res.json())
            .then(data => {
                // Sort the data by date_time in descending order
                const sortedData = data.sort((a, b) => new Date(b.date_time) - new Date(a.date_time));
                setLocations(sortedData);

                // Generate the concave hull for the sightings
                const points = sortedData.map(loc => turf.point([loc.longitude, loc.latitude]));
                const featureCollection = turf.featureCollection(points);
                const hull = turf.concave(featureCollection, { maxEdge: 650.0 }); // Adjust maxEdge for tighter boundary

                if (hull) {
                    setBoundary(hull);
                } else {
                    console.warn('No valid boundary generated, consider adjusting maxEdge parameter.');
                }
            })
            .catch(err => console.error("Error fetching data: ", err));
    }, []);

    // Custom frog icon using your provided image
    const frogIcon = new L.Icon({
        iconUrl: require('../toadtracker_resources/mapping_page/toad_icon.png'),
        iconSize: [35, 35],
        iconAnchor: [12, 24],
        popupAnchor: [0, -24],
    });

    // Convert locations to heatmap data format
    const heatmapPoints = locations.map(loc => [loc.latitude, loc.longitude, 0.5]);

    // Function to calculate cluster color based on intensity
    const getClusterColor = (count) => {
        const minCount = 0;
        const maxCount = 100; // Adjust this value based on your maximum cluster size
        const normalizedCount = Math.min(Math.max((count - minCount) / (maxCount - minCount), 0), 1);

        const interpolateColor = (startColor, endColor, factor) => {
            const start = startColor.slice(1).match(/.{2}/g).map(hex => parseInt(hex, 16));
            const end = endColor.slice(1).match(/.{2}/g).map(hex => parseInt(hex, 16));
            return `#${start.map((s, i) => Math.round(s + factor * (end[i] - s)).toString(16).padStart(2, '0')).join('')}`;
        };

        const startColor = '#FFFF00'; // Light yellow
        const endColor = '#FF0000';   // Dark red

        return interpolateColor(startColor, endColor, normalizedCount);
    };

    // Clustered Map component with color intensity and always visible boundaries
    const ClusterMap = () => {
        const map = useMap();

        useEffect(() => {
            const markers = L.markerClusterGroup({
                iconCreateFunction: (cluster) => {
                    const count = cluster.getChildCount();
                    const color = getClusterColor(count);
                    return new L.DivIcon({
                        html: `<div style="display: flex; align-items: center; justify-content: center; font-weight: bold; background-color:${color}; color: black; width: 25px; height: 25px; border-radius: 50%; box-shadow: 0 0 1px rgba(0,0,0,0.5);"><span>${count}</span></div>`,
                        className: 'marker-cluster',
                        iconSize: new L.Point(40, 40),
                    });
                },
                spiderfyOnMaxZoom: false,
                zoomToBoundsOnClick: true,
                disableClusteringAtZoom: 18,
            });

            locations.forEach(loc => {
                const marker = L.marker([loc.latitude, loc.longitude], { icon: frogIcon })
                    .bindPopup(`Cane Toad Localised: <br /> ${loc.date_time}`);
                markers.addLayer(marker);
            });

            map.addLayer(markers);

            return () => {
                map.removeLayer(markers);
            };
        }, [locations, map]);

        return null;
    };

    // Heatmap Layer component
    const HeatmapLayer = () => {
        const map = useMap();
    
        useEffect(() => {
            const gradient = {
                0.0: 'yellow',
                0.2: 'orange',
                0.5: 'red',
                0.7: 'darkred'
            };
    
            const heatLayer = L.heatLayer(heatmapPoints, {
                radius: 60,      // Increase radius for stronger visibility
                blur: 15,        // Increase blur for a smoother effect
                max: 1.0,        // Increase max to make the heatmap more intense
                gradient: gradient
            }).addTo(map);
    
            return () => {
                map.removeLayer(heatLayer);
            };
        }, [heatmapPoints, map]);
    
        return null;
    };
    

    // Reset button component to reset map view
    const ResetButton = () => {
        const map = useMap();

        const handleClick = () => {
            map.setView([-25.2744, 133.7751], 5); // Center of Australia with zoom level 5
        };

        return (
            <div className="leaflet-top leaflet-left" style={{ marginTop: '70px' }}> {/* Position below zoom buttons */}
                <div className="leaflet-control leaflet-bar">
                    <button
                        onClick={handleClick}
                        title="Reset View"
                        style={{
                            backgroundColor: '#fff',
                            padding: '5px 10px',
                            border: 'none',
                            cursor: 'pointer',
                            boxShadow: '0 1px 5px rgba(0, 0, 0, 0.65)'
                        }}
                    >
                        Reset
                    </button>
                </div>
            </div>
        );
    };

    // Format the date and time to be more readable
    const formatDateTime = (dateTime) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateTime).toLocaleDateString('en-US', options);
    };

    // Round latitude and longitude to 4 decimal places
    const formatCoordinate = (coordinate) => {
        return coordinate.toFixed(4);
    };

    const getSourceText = (type) => {
        return Number(type) === 1 ? 'Source: ToadTracker Pod' : 'Source: FrogID';
    };

    return (
        <>
            <NavBar />
            <div id="mapping" className="flex flex-col items-center w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto px-4 lg:px-20 text-center">
                    <h1 className="mb-5 md:text-5xl text-3xl font-bold text-green-900">Live Cane Toad Mapping</h1>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        Visualise a real-time map of localised cane toad positions across Australia from our MONGO database. Our advanced AI-driven pod system provides up-to-date data on cane toad hotspots and the cane toad invasion boundary, helping control teams act swiftly and efficiently. Australian Museum's FrogID data is also represented in the dataset.
                    </p>
                </div>

                {/* Interactive Map */}
                <div className="container mx-auto px-4 lg:px-20 text-center lg:text-left mt-8">
                    <h2 className="font-bold text-green-900 uppercase text-2xl mb-4">Interactive Map</h2>
                    <MapContainer 
                        center={[-25.2744, 133.7751]} 
                        zoom={5} 
                        minZoom={5}
                        scrollWheelZoom={true} 
                        className="h-[75vh] w-full"  // Adjust the height to make the map square
                    >
                        <ResetButton /> {/* Add Reset button to the map */}
                        <LayersControl position="topright">
                            <BaseLayer checked name="OpenStreetMap">
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                                />
                            </BaseLayer>
                            <BaseLayer name="Google Satellite">
                                <TileLayer
                                    url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                                    attribution='&copy; <a href="https://www.google.com/intl/en_ALL/help/terms_maps/">Google Maps</a>'
                                    subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                />
                            </BaseLayer>
                            <Overlay name="Feature Map">
                                <TileLayer
                                    url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
                                    attribution="Map data: &copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                                />
                            </Overlay>
                            <Overlay name="Heatmap">
                                <HeatmapLayer /> {/* Add HeatmapLayer component here */}
                            </Overlay>
                            <Overlay checked name="Clusters">
                                <ClusterMap /> {/* Add the ClusterMap component here */}
                            </Overlay>
                            <Overlay name="Invasion Boundary">
                                {boundary && (
                                    <Polygon 
                                        positions={boundary.geometry.coordinates[0].map(coord => [coord[1], coord[0]])} 
                                        color="red"
                                        weight={2} 
                                        fillOpacity={0.05} // More transparent
                                    />
                                )}
                            </Overlay>
                            <Overlay name="Australia Landmass"> {/* Add Landmass Layer */}
                                <GeoJSON data={landMassGeoJSON} style={{ color: 'green', weight: 2, fillOpacity: 0.01 }} />
                            </Overlay>
                        </LayersControl>
                        <ScaleControl position="bottomleft" /> {/* Add scale control */}
                        <FullscreenControl />
                        <GeocoderControl />
                    </MapContainer>
                </div>

                {/* Latest Cane Toad Localisations */}
                <div className="container mx-auto px-4 lg:px-20 text-center lg:text-left mt-8">
                    <h2 className="font-bold text-green-900 uppercase text-2xl mb-4">Latest Cane Toad Localisations</h2>
                    <ul className="list-disc list-inside text-lg text-gray-700 mt-4">
                        {locations.length ? locations.slice(0, 15).map(loc => (
                            <li key={loc._id} className="mb-2">
                                <strong>{formatDateTime(loc.date_time)} </strong>
                                {getSourceText(loc.type)} - 
                                Latitude: {formatCoordinate(loc.latitude)}, 
                                Longitude: {formatCoordinate(loc.longitude)}
                            </li>
                        )) : (
                            <li>No data available</li>
                        )}
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Mapping;
