import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../toadtracker_resources/main_page/main_drone_pic.png';  // Use a relevant ToadTracker image
import toadIcon from '../toadtracker_resources/main_page/devil_toad.png'; // Cane toad icon

const Hero = () => {
    const [toadPosition, setToadPosition] = useState({ left: window.innerWidth / 2 - 50, bottom: 170 });
    const [direction, setDirection] = useState(1); // Start facing the other way
    const [droneLoaded, setDroneLoaded] = useState(false); // Track if the drone image is loaded

    useEffect(() => {
        if (!droneLoaded) return; // Only start the animation if the drone image has fully loaded

        const toad = document.getElementById('cane-toad');
        const line = document.getElementById('tracking-line');
        const midPoint = 0;
        const maxRight = window.innerWidth - 150;

        const animateToad = () => {
            const currentLeft = parseInt(toad.style.left, 10) || midPoint;
            const newLeft = currentLeft + direction * 10;

            if (newLeft > maxRight || newLeft < midPoint) {
                setDirection(prevDirection => -prevDirection); // Flip direction
            }

            toad.style.left = `${newLeft}px`;

            // Update the toad position state for the line to follow
            setToadPosition({ left: newLeft, bottom: 170 });

            // Calculate line position
            const droneRect = document.getElementById('drone-img').getBoundingClientRect();
            const droneCenterX = droneRect.left + droneRect.width / 2 - 30; // Center of the drone image
            const droneCenterY = droneRect.top + droneRect.height / 2 + 30 + window.scrollY; // Adjust for scroll position

            const toadRect = toad.getBoundingClientRect();
            const toadCenterX = toadRect.left + toadRect.width / 2;
            const toadCenterY = toadRect.top + toadRect.height / 2 + window.scrollY; // Adjust for scroll position

            const length = Math.sqrt(
                Math.pow(toadCenterX - droneCenterX, 2) + Math.pow(toadCenterY - droneCenterY, 2)
            );
            const angle = Math.atan2(toadCenterY - droneCenterY, toadCenterX - droneCenterX) * (180 / Math.PI);

            line.style.width = `${length}px`;
            line.style.transform = `rotate(${angle}deg)`;
            line.style.left = `${droneCenterX}px`;
            line.style.top = `${droneCenterY}px`; // Adjusting the Y position to match the toad's Y position
        };

        // Set up the interval to animate the toad
        const intervalId = setInterval(animateToad, 20);

        return () => clearInterval(intervalId);
    }, [direction, droneLoaded]); // Re-run effect when direction changes and droneLoaded is true

    return (
        <>
            <div 
                className="hero bg-gradient-to-r from-green-50 to-green-200 py-12 lg:py-24 relative" 
                id="hero"
            >
                <div>
                    <NavBar />
                </div>
                
                <div 
                    className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" 
                >
                    <div 
                        id="hero" 
                        className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left"
                    >
                        <div 
                            className="lg:w-1/2 flex flex-col justify-center" 
                        >
                            <h1 
                                className="mb-5 md:text-6xl text-4xl font-extrabold text-green-900 leading-tight animate-fade-in"
                            >
                                Autonomous Cane Toad Mapping System
                            </h1>
                            <div 
                                className="text-xl font-semibold tracking-tight mb-5 text-gray-600 lg:text-lg animate-slide-up"
                            >
                                AI-powered drone pods to protect Australia's wildlife by mapping and monitoring invasive cane toads.
                            </div>
                            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                                <Link 
                                    to="/contact" 
                                    className="text-white bg-gradient-to-r from-blue-800 to-blue-600 hover:scale-105 transform transition-all duration-300 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 shine-effect"
                                >
                                    Learn more
                                    <svg 
                                        className="w-4 h-4 ml-1" 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        viewBox="0 0 20 20" 
                                        fill="currentColor"
                                    >
                                        <path 
                                            fillRule="evenodd" 
                                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" 
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </Link>
                            </div>
                        </div>
                        <div 
                            id="drone-img"  // Assigning an ID for accurate calculations
                            className="flex lg:justify-end w-full lg:w-1/2 relative" 
                        >
                            <img 
                                alt="ToadTracker Drone" 
                                className="rounded-t float-right duration-1000 w-80% drop-shadow-lg hover:scale-105 transform transition-all ease-out ellipse-frame" 
                                src={heroImg} 
                                onLoad={() => setDroneLoaded(true)} // Set to true when the drone image loads
                            />
                        </div>
                    </div>
                </div>

                {/* Cane Toad Animation, only render when droneLoaded is true */}
                {droneLoaded && (
                    <>
                        <div 
                            id="cane-toad"
                            style={{
                                position: 'absolute',
                                bottom: `-55px`,
                                left: `${toadPosition.left}px`,
                                width: '200px',
                                height: 'auto',
                                zIndex: 10, // Ensure the toad is in front of the line
                                transform: `scaleX(${direction})`, // Flip toad based on direction
                            }}
                        >
                            <img 
                                src={toadIcon} 
                                alt="Cane Toad" 
                                style={{
                                    width: '100%',
                                }}
                            />
                        </div>

                        {/* Line Tracking the Toad */}
                        <div 
                            id="tracking-line"
                            style={{
                                position: 'absolute',
                                height: '2px',  // Increased thickness
                                backgroundColor: 'blue',  // Changed color to blue
                                transformOrigin: '0 0',
                                animation: 'pulseBlue 1s infinite',
                                zIndex: 5, // Lower zIndex to place the line behind the toad
                            }}
                        ></div>
                    </>
                )}
            </div>

            {/* CSS for pulsing animation */}
            <style>{`
                @keyframes pulseBlue {
                    0% {
                        opacity: 1;
                        background-color: blue;
                    }
                    50% {
                        opacity: 0.5;
                        background-color: lightblue;
                    }
                    100% {
                        opacity: 1;
                        background-color: blue;
                    }
                }
            `}</style>
        </>
    );
}

export default Hero;
