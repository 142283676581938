import React from 'react';
import NavBar from '../components/Navbar/NavBar';

const IntroHOW = () => {
    return (
        <>
            <div 
                className="relative bg-gradient-to-br from-gray-800 to-gray-900 h-screen flex items-center justify-center overflow-hidden pt-16 lg:pt-32"
                id="intro-how"
            >
                <NavBar />

                <div className="container mx-auto px-4 lg:px-24 text-center text-white">
                    <div className="relative">
                        <h1 className="text-4xl md:text-6xl font-extrabold leading-tight mb-8 animate-fade-in">
                            How ToadTracker Works
                        </h1>
                        <p className="text-xl md:text-2xl font-light mb-8 animate-slide-up">
                            The ToadTracker system is composed of three main components that work together to detect, locate, and map invasive cane toads in real-time, helping to protect Australia's unique wildlife.
                        </p>

                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 text-left mt-8">
                            <div className="bg-gray-700 p-8 rounded-lg shadow-lg hover:scale-105 transform transition-all duration-300 flex flex-col justify-between">
                                <div>
                                    <h2 className="text-2xl font-bold text-white mb-4">Identification</h2>
                                    <p className="text-lg leading-relaxed text-gray-200 mb-8">
                                        Using advanced AI-driven models, the system analyzes environmental sounds to identify the distinct calls of cane toads. This ensures accurate detection even in noisy environments, distinguishing cane toad calls from other ambient noises.
                                    </p>
                                </div>
                                <a 
                                    href="#recognition-how" 
                                    className="block text-center text-white bg-gradient-to-r from-blue-600 to-blue-400 hover:scale-110 transform transition-all duration-300 inline-flex items-center justify-center px-6 py-3 text-lg shadow-lg rounded-full"
                                >
                                    Find out how
                                </a>
                            </div>
                            <div className="bg-gray-700 p-8 rounded-lg shadow-lg hover:scale-105 transform transition-all duration-300 flex flex-col justify-between">
                                <div>
                                    <h2 className="text-2xl font-bold text-white mb-4">Localization</h2>
                                    <p className="text-lg leading-relaxed text-gray-200 mb-8">
                                        Utilizing sophisticated beamforming techniques and GPS technology, the ToadTracker pinpoints the exact location of the toads, allowing for precise tracking over large areas.
                                    </p>
                                </div>
                                <a 
                                    href="#localisation-how" 
                                    className="block text-center text-white bg-gradient-to-r from-green-600 to-green-400 hover:scale-110 transform transition-all duration-300 inline-flex items-center justify-center px-6 py-3 text-lg shadow-lg rounded-full"
                                >
                                    Find out how
                                </a>
                            </div>
                            <div className="bg-gray-700 p-8 rounded-lg shadow-lg hover:scale-105 transform transition-all duration-300 flex flex-col justify-between">
                                <div>
                                    <h2 className="text-2xl font-bold text-white mb-4">Mapping</h2>
                                    <p className="text-lg leading-relaxed text-gray-200 mb-8">
                                        The system generates detailed maps of cane toad populations, including their movements and breeding sites. This real-time data is essential for targeted interventions and effective management of the invasive species.
                                    </p>
                                </div>
                                <a 
                                    href="#mapping-how" 
                                    className="block text-center text-white bg-gradient-to-r from-purple-600 to-purple-400 hover:scale-110 transform transition-all duration-300 inline-flex items-center justify-center px-6 py-3 text-lg shadow-lg rounded-full"
                                >
                                    Find out how
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IntroHOW;
