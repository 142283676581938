import React from 'react';
import main_pic from '../toadtracker_resources/main_page/cane_toad_audio_spectrogram.PNG';
import picture7 from '../toadtracker_resources/pod_page/recognition/Picture7.png';
import picture18 from '../toadtracker_resources/pod_page/recognition/Picture18.png';
import picture19 from '../toadtracker_resources/pod_page/recognition/Picture19.png';
import picture20 from '../toadtracker_resources/pod_page/recognition/Picture20.png';
import picture21 from '../toadtracker_resources/pod_page/recognition/Picture21.png';
import picture22 from '../toadtracker_resources/pod_page/recognition/Picture22.png';
import picture23 from '../toadtracker_resources/pod_page/recognition/Picture23.png';
import picture24 from '../toadtracker_resources/pod_page/recognition/Picture24.png';
import picture25 from '../toadtracker_resources/pod_page/recognition/Picture25.png';
import picture26 from '../toadtracker_resources/pod_page/recognition/Picture26.png';
import picture27 from '../toadtracker_resources/pod_page/recognition/Picture27.png';
import picture28 from '../toadtracker_resources/pod_page/recognition/Picture28.png';
import picture29 from '../toadtracker_resources/pod_page/recognition/Picture29.png';
import picture30 from '../toadtracker_resources/pod_page/recognition/Picture30.png';
import picture31 from '../toadtracker_resources/pod_page/recognition/Picture31.png';
import picture32 from '../toadtracker_resources/pod_page/recognition/Picture32.png';
import picture33 from '../toadtracker_resources/pod_page/recognition/Picture33.png';
import picture34 from '../toadtracker_resources/pod_page/recognition/Picture34.jpg';

const imageSizeClass = "max-h-72 w-auto object-contain mx-auto rounded-lg shadow-lg hover:scale-105 transform transition-all duration-300 mb-4"; // Standardized image size

const RecognitionHOW = () => {
    return (
        <>
            <div 
                className="hero bg-gradient-to-r from-green-50 to-green-200 py-12 lg:py-24 relative" 
                id="recognition-how"
            >
                <div 
                    className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" 
                >
                    <div 
                        id="recognition-how" 
                        className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left"
                    >
                        <div 
                            className="lg:w-1/2 flex flex-col justify-center" 
                        >
                            <h1 
                                className="mb-5 md:text-6xl text-4xl font-extrabold text-green-900 leading-tight animate-fade-in"
                            >
                                Cane Toad Sound AI Recognition
                            </h1>
                            <div 
                                className="text-xl font-semibold tracking-tight mb-5 text-gray-600 lg:text-lg animate-slide-up"
                            >
                                ToadTracker uses advanced sound AI techniques for the accurate recognition and classification of cane toad calls in the most complex and noisy drone-based environments.
                            </div>
                        </div>
                        <div 
                            id="recognition-img"  
                            className="flex lg:justify-end w-full lg:w-1/2 relative" 
                        >
                            <img 
                                alt="ToadTracker Recognition" 
                                className={`rounded-t w-50% float-right duration-1000 drop-shadow-lg hover:scale-105 transform transition-all ease-out ellipse-frame`} 
                                src={main_pic} 
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 lg:px-20 text-center pt-24 lg:pt-32">
                <h2 className="font-bold text-green-800 uppercase text-3xl mb-6 text-center">Spectrogram Identification</h2>
                <p className="text-xl text-gray-700 leading-relaxed mb-6">
                    Our system leverages deep learning models trained on extensive datasets to recognize the distinct acoustic signature of cane toad calls on spectrograms, even in challenging environments mounted to a multi-propellor drone. This process involves several key steps including data preprocessing, feature extraction, and model prediction.
                </p>
                <div className="mb-16">
                    <img src={picture18} alt="Real-time Detection and Localization" className={imageSizeClass} />
                </div>
                <div className="mb-16">
                    <img src={picture21} alt="Real-time Detection and Localization" className={imageSizeClass} />
                    <p className="text-lg text-gray-700 leading-relaxed">Even in the most complex and noisy environments, it is still possible to recognize the distinct call of a cane toad.</p>
                </div>
            </div>

            <div className="container mx-auto px-4 lg:px-20 text-center pt-24 lg:pt-32">
                <h2 className="font-bold text-green-800 uppercase text-3xl mb-6 text-center">Spectral Subtraction</h2>
                <p className="text-xl text-gray-700 leading-relaxed mb-6">
                    Of utmost importance is the ability of the system to pick up cane toad calls amongst the sound of variable drone propellors. Spectral subtraction is applied in our preprocessing step. By using directional microphones, the phase of one signal can be flipped and then both signals added together to remove the unwanted noise from the main signal.
                </p>
                <div className="mb-16">
                    <img src={picture23} alt="Model Architecture" className="max-h-288 w-auto object-contain mx-auto rounded-lg shadow-lg hover:scale-105 transform transition-all duration-300 mb-4" />
                    <p className="text-lg text-gray-700 leading-relaxed">Spectral subtraction of propellor noise</p>
                </div>
            </div>

            <div className="container mx-auto px-4 lg:px-20 text-center pt-24 lg:pt-32">
                <h2 className="font-bold text-green-800 uppercase text-3xl mb-6 text-center">AI Model Architecture Development</h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    <div className="mb-16">
                        <img src={picture7} alt="Data Augmentation Techniques" className={imageSizeClass} />
                        <p className="text-lg text-gray-700 leading-relaxed">Data Augmentation Techniques - To improve model generalization, data augmentation techniques such as noise addition and pitch shifting are applied during training.</p>
                    </div>
                    <div className="mb-16">
                        <img src={picture24} alt="Data Labeling" className={imageSizeClass} />
                        <p className="text-lg text-gray-700 leading-relaxed">Data Labeling - Sound data must be manually labeled by hand, this is a tedious process, but extremely important.</p>
                    </div>

                    <div className="mb-16">
                        <img src={picture26} alt="Model Training Process" className={imageSizeClass} />
                        <p className="text-lg text-gray-700 leading-relaxed">Model Training Process - The model undergoes rigorous training on a high-performance GPU cluster, optimizing it for real-time performance in the field.</p>
                    </div>

                    <div className="mb-16">
                        <img src={picture25} alt="Real-world Testing" className={imageSizeClass} />
                        <p className="text-lg text-gray-700 leading-relaxed">Example of one of the Deep+Wide network architectures developed to perform the AI recognition.</p>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 lg:px-20 text-center pt-24 lg:pt-32">
                <h2 className="font-bold text-green-800 uppercase text-3xl mb-6 text-center">AI Model Benchmarking and Performance</h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    <div className="mb-16">
                        <img src={picture27} alt="Performance Metrics" className={imageSizeClass} />
                        <p className="text-lg text-gray-700 leading-relaxed">Validation accuracy, which is the same as detection accuracy.</p>
                    </div>

                    <div className="mb-16">
                        <img src={picture28} alt="Deployment Setup" className={imageSizeClass} />
                        <p className="text-lg text-gray-700 leading-relaxed">Validation loss of the model's training.</p>
                    </div>

                    <div className="mb-16">
                        <img src={picture31} alt="AI System Updates" className={imageSizeClass} />
                        <p className="text-lg text-gray-700 leading-relaxed">Benchmarked accuracy for the different models.</p>
                    </div>

                    <div className="mb-16">
                        <img src={picture32} alt="Future Enhancements" className={imageSizeClass} />
                        <p className="text-lg text-gray-700 leading-relaxed">The difference in processing time for each sample for each different model.</p>
                    </div>

                    <div className="mb-16">
                        <img src={picture29} alt="Live Monitoring Interface" className={imageSizeClass} />
                        <p className="text-lg text-gray-700 leading-relaxed">Effect of segment size on model accuracy.</p>
                    </div>

                    <div className="mb-16">
                        <img src={picture30} alt="Model Fine-tuning" className={imageSizeClass} />
                        <p className="text-lg text-gray-700 leading-relaxed">Effect of segment size on prediction time.</p>
                    </div>

                    <div className="mb-16">
                        <img src={picture33} alt="Collaboration and Partnerships" className={imageSizeClass} />
                        <p className="text-lg text-gray-700 leading-relaxed">Metrics for benchmarking the different AI models developed and trained.</p>
                    </div>

                    <div className="mb-16">
                        <img src={picture34} alt="Continuous Learning" className={imageSizeClass} />
                        <p className="text-lg text-gray-700 leading-relaxed">Example test rig for real-life testing of the recognition AI model.</p>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 lg:px-20 text-center py-16 lg:py-20 rounded-lg shadow-lg">
                <h2 className="font-bold text-green-900 uppercase text-3xl mb-6 text-center">Explanation</h2>
                <ul className="text-xl text-gray-800 leading-relaxed mb-4 list-disc list-inside">
                    <li>Sound was converted into spectrograms using FFT for visual pattern recognition.</li>
                    <li>108 neural networks were developed, trained, and benchmarked against current audio recognition models.</li>
                    <li>A weighted averaging method removed bias to find the optimal model.</li>
                    <li>Models varied from shallow-narrow CNNs to deep-wide RNNs.</li>
                    <li>Audio data was augmented and mixed with soundscapes to enhance accuracy.</li>
                    <li>Propeller audio was included to train models to ignore propeller noise.</li>
                    <li>Transfer learning was tested but failed due to computational needs and model overcapacity.</li>
                    <li>The final model achieved 91% identification accuracy with a 0.025-second prediction time on a Raspberry Pi 4 (8GB).</li>
                </ul>
                <h2 className="font-bold text-green-900 uppercase text-3xl mb-6 text-center">TLDR</h2>
                <p className="text-xl text-gray-800 leading-relaxed mb-4">
                    A 91% accurate cane toad sound identification AI was developed using 108 neural networks, optimized for rapid prediction (0.025s) on a Raspberry Pi 4.
                </p>
            </div>

        </>
    );
}

export default RecognitionHOW;
