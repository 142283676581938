import React from 'react';
import { Link } from 'react-router-dom';
import svgLogo1 from '../toadtracker_resources/main_page/svg_mapping.svg';
import svgLogo2 from '../toadtracker_resources/main_page/svg_pod.svg';
import svgLogo3 from '../toadtracker_resources/main_page/svg_support.svg';

const Portfolio = () => {
    return (
        <div className="my-4 py-4" id='portfolio'>
            <h2 className="my-2 text-center text-3xl text-green-900 uppercase font-bold">Where to next?</h2>
            <div className='flex justify-center'>
                <div className='w-24 border-b-4 border-green-900 mb-8'></div>
            </div>

            <div className="px-4">
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {/* Live Mapping of Cane Toad Positions */}
                    <Link to="/mapping" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-green-700 hover:scale-105 rounded-lg shadow-2xl p-3 group h-auto">
                        <div className="m-2 text-justify text-lg flex flex-col justify-between h-full"> 
                            <h4 className="font-semibold mb-2 text-xl md:text-2xl text-center h-14">Live Mapping</h4>
                            <img src={svgLogo1} alt="Live Mapping" className="mx-auto mb-4 h-16 transition-transform duration-300 ease-in-out group-hover:scale-150"/>
                            <p className="text-lg font-medium leading-6 mb-6 text-center">
                                Access real-time maps showing the locations of localized cane toads, allowing for rapid responses to potential threats and enabling more effective management strategies.
                            </p>
                            <div className="flex justify-center mt-1 mb-6">
                                <a 
                                    className="text-white bg-gradient-to-r from-blue-800 to-blue-600 hover:scale-105 transform transition-all duration-300 inline-flex items-center justify-center w-full px-6 py-3 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 shine-effect"
                                >
                                    Find out where
                                </a>
                            </div>
                        </div>
                    </Link>

                    {/* Pod Specifications */}
                    <Link to="/pod" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-green-700 hover:scale-105 rounded-lg shadow-2xl p-3 group h-auto">
                        <div className="m-2 text-justify text-lg flex flex-col justify-between h-full">
                            <h4 className="font-semibold mb-2 text-xl md:text-2xl text-center h-14">Pod Specifications</h4>
                            <img src={svgLogo2} alt="Pod Specifications" className="mx-auto mb-4 h-16 transition-transform duration-300 ease-in-out group-hover:scale-150"/>
                            <p className="text-lg font-medium leading-6 mb-6 text-center">
                                Delve into the advanced specifications of the ToadTracker pod, including its cutting-edge AI algorithms, high-precision microphone array, and performance metrics.
                            </p>
                            <div className="flex justify-center mt-1 mb-6">
                                <a 
                                    className="text-white bg-gradient-to-r from-blue-800 to-blue-600 hover:scale-105 transform transition-all duration-300 inline-flex items-center justify-center w-full px-6 py-3 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 shine-effect"
                                >
                                    Find out how
                                </a>
                            </div>
                        </div>
                    </Link>

                    {/* Support Us & About */}
                    <Link to="/Support" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-green-700 hover:scale-105 rounded-lg shadow-2xl p-3 group h-auto">
                        <div className="m-2 text-justify text-lg flex flex-col justify-between h-full">
                            <h4 className="font-semibold mb-2 text-xl md:text-2xl text-center h-14">Support Us & About</h4>
                            <img src={svgLogo3} alt="Support Us" className="mx-auto mb-4 h-16 transition-transform duration-300 ease-in-out group-hover:scale-150"/>
                            <p className="text-lg font-medium leading-6 mb-6 text-center">
                                Discover more about our mission and the dedicated team behind ToadTracker, and learn how you can contribute to the fight against the invasive cane toad.
                            </p>
                            <div className="flex justify-center mt-1 mb-6">
                                <a 
                                    className="text-white bg-gradient-to-r from-blue-800 to-blue-600 hover:scale-105 transform transition-all duration-300 inline-flex items-center justify-center w-full px-6 py-3 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 shine-effect"
                                >
                                    Find out who
                                </a>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>

        </div>
    );
}

export default Portfolio;
