import React from 'react';
import landcare from '../images/clients/landcare.png'; // Update with actual image path
import nswGov from '../images/clients/nsw_gov.png'; // Update with actual image path
import monash from '../images/clients/monash.png'; // Update with actual image path
import frogid from '../images/clients/frogid.png'; // Update with actual image path
import museum from '../images/clients/museum.png'; // Update with actual image path

const clientImage = {
    height: '10rem',
    width: 'auto',
    mixBlendMode: 'colorBurn'
};

const Clients = () => {
    return (
        <div className="mt-8 ">
            <section>
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-green-900 uppercase font-bold">Our Collaborators</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-green-900'></div>
                    </div>
                </div>

                <div className="p-16">
                    <div className="grid sm:grid-cols-5 lg:grid-cols-5">
                        <div style={clientImage} className="overflow-hidden flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100 w-1/6">
                            <img src={landcare} alt="Landcare NSW" />  {/* Landcare NSW */}                         
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={nswGov} alt="NSW Government" />  {/* NSW Government */}                          
                        </div> 

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={monash} alt="Monash University" />  {/* Monash University */}                         
                        </div>
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={frogid} alt="FrogID" />  {/* FrogID */}                         
                        </div>
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={museum} alt="Museum" />  {/* Museum */}                         
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Clients;
