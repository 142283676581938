import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../toadtracker_resources/LOGO1-removebg-preview.png';  // Import your logo

const Footer = () => {
    return (
        <footer className="footer py-4 bg-gray-100">  {/* Reduced padding */}
            <div className="container mx-auto px-4 sm:px-6">

                {/* Top area: Blocks */}
                <div className="grid sm:grid-cols-12 gap-4 py-4 md:py-6 border-t border-gray-300"> {/* Reduced gap and padding */}

                    {/* 1st block */}
                    <div className="col-span-12 lg:col-span-4 flex items-center justify-center lg:justify-start">
                        <div className="flex items-center">
                            <img src={logo} alt="ToadTracker Logo" className="h-10 w-auto mr-2" /> {/* Reduced height and margin */}
                            <div className="border-l border-black h-10 mx-2"></div> {/* Reduced height and margin for the vertical line */}
                        </div>
                        <div className="text-left ml-2"> {/* Reduced margin */}
                            <h3 className="font-bold text-2xl mb-1 text-black">ToadTracker</h3> {/* Reduced font size and margin */}
                            <div className="text-sm font-medium text-gray-700"> {/* Reduced font size */}
                                <p>Cane Toad Mapping</p>
                                <p>Sunshine Coast QLD & Chadstone VIC</p>
                            </div>
                        </div>
                    </div>

                    {/* 2nd block */}
                    <div className="col-span-6 md:col-span-4 lg:col-span-3 flex flex-col lg:ml-10 lg:items-start"> {/* Reduced margin */}
                        <h6 className="text-black text-lg font-bold mb-2">Links</h6> {/* Reduced font size and margin */}
                        <ul className="text-sm text-gray-700 space-y-1"> {/* Reduced font size and spacing */}
                            <li>
                                <Link to="/mapping" className="hover:text-black transition duration-300 ease-in-out">Live Mapping</Link>
                            </li>
                            <li>
                                <Link to="/pod" className="hover:text-black transition duration-300 ease-in-out">Pod</Link>
                            </li>
                            <li>
                                <Link to="/howitworks" className="hover:text-black transition duration-300 ease-in-out">How it works</Link>
                            </li>
                            <li>
                                <Link to="/support" className="hover:text-black transition duration-300 ease-in-out">Support Us</Link>
                            </li>
                            <li>
                                <Link to="/#about" className="hover:text-black transition duration-300 ease-in-out">About</Link>
                            </li>
                        </ul>
                    </div>

                    {/* 3rd block - Contact Information */}
                    <div className="col-span-12 lg:col-span-4 flex flex-col lg:items-start lg:ml-10"> {/* Reduced margin */}
                        <h6 className="text-black text-lg font-bold mb-2">Get in Touch</h6> {/* Reduced font size and margin */}
                        <p className="text-sm font-medium text-gray-700 mb-2">Sunshine Coast QLD & Chadstone VIC</p> {/* Reduced font size and margin */}
                        <p className="text-sm font-medium text-gray-700 mb-2">Tel: 61431877207</p> {/* Reduced font size and margin */}
                        <p className="text-sm font-medium text-gray-700 mb-2">Email: contact@toadtracker.com.au</p> {/* Reduced font size and margin */}
                        <ul className="flex space-x-2"> {/* Reduced spacing */}
                            <li>
                                <a href="https://www.facebook.com/toadtracker" target="_blank" rel="noreferrer" className="flex justify-center items-center text-black hover:text-green-600 bg-white p-1 rounded-full shadow-lg transition duration-150 ease-in-out" aria-label="Facebook"> {/* Reduced padding */}
                                    <svg className="w-4 h-4 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"> {/* Reduced size */}
                                        <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/toad-tracker/" target="_blank" rel="noreferrer" className="flex justify-center items-center text-black hover:text-green-600 bg-white p-1 rounded-full shadow-lg transition duration-150 ease-in-out" aria-label="LinkedIn"> {/* Reduced padding */}
                                    <svg className="w-4 h-4 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"> {/* Reduced size */}
                                        <circle cx="4.983" cy="5.009" r="2.188"></circle>
                                        <path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>          

                </div>

                {/* Bottom area */}
                <div className="flex flex-wrap items-center justify-center md:justify-between border-t border-gray-300 pt-4 mt-4"> {/* Reduced padding and margin */}
                    <div className="text-xs text-gray-700 text-center md:text-left"> {/* Reduced font size */}
                        &copy; {new Date().getFullYear()} ToadTracker. All rights reserved.
                    </div>
                </div>

            </div>
        </footer>
    );
}

export default Footer;
