import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import pod2 from '../toadtracker_resources/pod_page/pod/pod2.PNG';
import pod3 from '../toadtracker_resources/pod_page/pod/pod3.png';
import pod4 from '../toadtracker_resources/pod_page/pod/pod4.png';
import pod5 from '../toadtracker_resources/pod_page/pod/pod5.png';
import picture45 from '../toadtracker_resources/pod_page/pod/Picture45.png';
import report1 from '../toadtracker_resources/pod_page/pod/report1.png';
import Footer from '../components/Footer';


const PodHOW = () => {
    const [isZoomed, setIsZoomed] = useState(false);

    return (
        <>
            <div className="relative bg-gradient-to-br from-green-200 to-green-500 h-screen flex items-center justify-center overflow-hidden pt-24 lg:pt-48 pb-12 lg:pb-24">
                <NavBar />

                <div className="container mx-auto px-4 lg:px-24 text-center">
                    <div className="relative">
                        <h1 className="text-5xl md:text-7xl font-extrabold text-white mb-4">Drone Pod Specifications</h1>
                        <p className="text-2xl text-white mb-8 animate-fade-in-down">
                            The pod is an aerodynamic teardrop shape for maximum efficiency, printed from advanced materials, with steel-bolted connections, designed for durability and ease of maintenance.
                        </p>
                        <img 
                            src={pod2} 
                            alt="Pod Image 2" 
                            className={`rounded-lg shadow-lg hover:scale-105 transform transition-all duration-300 mx-auto cursor-pointer ${isZoomed ? 'scale-150' : ''}`}
                            style={{ maxWidth: '80%' }}
                        />
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 lg:px-20 text-center pt-16 lg:pt-24">
                <div className="flex flex-col lg:flex-row justify-around items-start space-y-8 lg:space-y-0 lg:space-x-8">
                    <div className="bg-white shadow-lg rounded-lg p-6 text-left w-full lg:w-1/2">
                        <h3 className="text-2xl font-bold text-gray-800 mb-4">General Specifications</h3>
                        <ul className="list-none text-gray-700 space-y-4">
                            <li><strong>GPS Integration:</strong> Enables precise location mapping to ±1m.</li>
                            <li><strong>Autonomous Operation:</strong> On board processing and update of cane toad positions to our database.</li>
                            <li><strong>Modular Design:</strong> Mountable on various drones, with adaptable power options and custom mounts available.</li>
                            <li><strong>Power Supply:</strong> Can be powered by an in-built 5V battery or connected to the drone’s main battery.</li>
                            <li><strong>Enhanced Cooling:</strong> Designed for optimal airflow to maintain performance and keep the processors cool.</li>
                        </ul>
                    </div>
                    <div className="bg-white shadow-lg rounded-lg p-6 text-left w-full lg:w-1/2">
                        <h3 className="text-2xl font-bold text-gray-800 mb-4">Technical Specifications</h3>
                        <ul className="list-none text-gray-700 space-y-4">
                            <li><strong>Weight:</strong> 805 grams</li>
                            <li><strong>Operation Time:</strong> 2.5 hours</li>
                            <li><strong>Detection Range:</strong> 22.5 meters with ±2.5m accuracy - With plans to reach 50m of range with newer microphones</li>
                            <li><strong>AI Identification Accuracy:</strong> 91% hit rate with thick propellor noise on unseen cane toad calls</li>
                            <li><strong>Prediction Time:</strong> 0.025 seconds</li>
                            <li><strong>Max Cane Toad Detection:</strong> Up to 4 toads simultaneously</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 lg:px-20 text-center pt-16 lg:pt-24">
                <div className="grid grid-cols-2 lg:grid-cols-5 gap-8 mb-8">
                    <img 
                        src={pod3} 
                        alt="Pod Image 3" 
                        className="rounded-lg shadow-lg hover:scale-150 transform transition-all duration-500 hover:rotate-2"
                    />
                    <img 
                        src={pod4} 
                        alt="Pod Image 4" 
                        className="rounded-lg shadow-lg hover:scale-150 transform transition-all duration-500 hover:-rotate-2"
                    />
                    <img 
                        src={pod5} 
                        alt="Pod Image 5" 
                        className="rounded-lg shadow-lg hover:scale-150 transform transition-all duration-500 hover:rotate-1"
                    />
                    <img 
                        src={picture45} 
                        alt="Pod Image 45" 
                        className="rounded-lg shadow-lg hover:scale-150 transform transition-all duration-500 hover:rotate-1"
                    />
                    <img 
                        src={report1} 
                        alt="Pod Image 8" 
                        className="rounded-lg shadow-lg hover:scale-150 transform transition-all duration-500 hover:-rotate-1"
                    />
                </div>
                

                <div className="container mx-auto px-4 lg:px-20 text-center py-16 lg:py-20 bg-green-100 rounded-lg shadow-lg"> 
                    <h2 className="font-bold text-green-900 uppercase text-3xl mb-6 text-center">TLDR</h2>
                    <p className="text-xl text-gray-800 leading-relaxed mb-4">
                        The 805g 3D printed pod, with 2.5 hours of operation, mounts on various aircraft, integrates Pi systems and sensors, achieving an experimental static accuracy of ±1.39m, with further testing needed for dynamic accuracy.
                    </p>
                </div>

            </div>
            <Footer />
        </>
    );
}

export default PodHOW;
