import React from 'react';
import { Link } from 'react-router-dom';
import picture8 from '../toadtracker_resources/pod_page/localisation/Picture8.png';
import picture9 from '../toadtracker_resources/pod_page/localisation/Picture9.png';
import picture10 from '../toadtracker_resources/pod_page/localisation/Picture10.jpg';
import picture11 from '../toadtracker_resources/pod_page/localisation/Picture11.png';
import picture12 from '../toadtracker_resources/pod_page/localisation/Picture12.png';
import picture13 from '../toadtracker_resources/pod_page/localisation/Picture13.png';
import picture14 from '../toadtracker_resources/pod_page/localisation/Picture14.png';
import picture35 from '../toadtracker_resources/pod_page/localisation/Picture35.png';
import picture36 from '../toadtracker_resources/pod_page/localisation/Picture36.png';
import picture47 from '../toadtracker_resources/pod_page/localisation/Picture47.jpg';

const imageSizeClass = "max-h-72 w-auto object-contain mx-auto rounded-lg shadow-lg hover:scale-105 transform transition-all duration-300 mb-4"; // Standardized image size

const LocalisationHOW = () => {
    return (
        <>
            <div 
                className="hero bg-gradient-to-r from-blue-50 to-blue-200 py-12 lg:py-24 relative" 
                id="localisation-how"
            >
                
                <div 
                    className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" 
                >
                    <div 
                        id="localisation-how" 
                        className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left"
                    >
                        <div 
                            className="lg:w-1/2 flex flex-col justify-center" 
                        >
                            <h1 
                                className="mb-5 md:text-6xl text-4xl font-extrabold text-blue-900 leading-tight animate-fade-in"
                            >
                                Localisation using beamforming on a 6-microphone array
                            </h1>
                            <div 
                                className="text-xl font-semibold tracking-tight mb-5 text-gray-600 lg:text-lg animate-slide-up"
                            >
                                The ToadTracker Pod uses advanced sound localization techniques to accurately identify and track cane toad populations in real-time. Here's how the system pinpoints the exact location of these invasive species.
                            </div>
                        </div>
                        <div 
                            id="localisation-img"  
                            className="flex lg:justify-end w-full lg:w-1/2 relative" 
                        >
                            <img 
                                alt="Localization Process" 
                                className="rounded-t float-right duration-1000 w-80% drop-shadow-lg hover:scale-105 transform transition-all ease-out ellipse-frame" 
                                src={picture8} 
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div id="details" className="container mx-auto px-4 lg:px-20 py-16 lg:py-24">
                <h2 className="font-bold text-blue-800 uppercase text-3xl mb-6 text-center">Details of Localization</h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                    The ToadTracker Pod utilizes a phased array of microphones to detect and localize the source of cane toad calls. This system relies on beamforming techniques to isolate sounds coming from specific directions, significantly enhancing the accuracy of the localization process.
                </p>
                <div className="flex justify-center mb-8">
                    <img src={picture9} alt="Microphone Array and Localization" className={imageSizeClass} />
                </div>

                <p className="text-lg text-gray-700 leading-relaxed mt-6">
                    The system applies advanced algorithms, including the Generalized Cross-Correlation with Phase Transform (GCC-PHAT) and Multiple Signal Classification (MUSIC), to determine the precise angle of arrival of sound waves. These algorithms are integral to the system's ability to track multiple cane toads simultaneously.
                </p>
                <div className="flex justify-center mb-8">
                    <img src={picture10} alt="GCC-PHAT Algorithm Process" className={imageSizeClass} />
                </div>

                <h2 className="font-bold text-blue-800 uppercase text-3xl mb-6 text-center">Real-Time Tracking</h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                    With the integration of GPS and other localization technologies, the ToadTracker Pod can accurately map the locations of detected cane toads in real-time. This real-time data is crucial for effective monitoring and intervention strategies.
                </p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
                    <img src={picture11} alt="Real-Time Tracking in Action" className={imageSizeClass} />
                    <img src={picture12} alt="Localization and Tracking Example" className={imageSizeClass} />
                </div>

                <h2 className="font-bold text-blue-800 uppercase text-3xl mb-6 mt-16 text-center">Tested Accuracy</h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                    Extensive testing has been conducted to ensure the accuracy and reliability of the ToadTracker Pod's localization system. The system has demonstrated consistent performance under various environmental conditions, with a high degree of accuracy in pinpointing cane toad locations.
                </p>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
                    <img src={picture13} alt="Localization Accuracy Test" className={imageSizeClass} />
                    <img src={picture14} alt="Azimuth and Elevation Analysis" className={imageSizeClass} />
                    <img src={picture35} alt="Microphone Array Sensitivity" className={imageSizeClass} />
                    <img src={picture36} alt="Sound Source Localization and Tracking" className={imageSizeClass} />
                </div>

                <h2 className="font-bold text-blue-800 uppercase text-3xl mb-6 mt-16 text-center">Effect of microphone quantity</h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                    Effect of microphone quantity
                </p>
                <div className="flex justify-center mb-8">
                    <img src={picture47} alt="Field Testing and Real-World Validation" className={imageSizeClass} />
                </div>
            </div>

            <div className="container mx-auto px-4 lg:px-20 text-center lg:pt-32">
                <h2 className="font-bold text-blue-800 uppercase text-3xl mb-6 text-center">Explanation</h2>
                <ul className="text-xl text-gray-700 leading-relaxed mb-4 list-disc list-inside">
                    <li>An array-based sound localization platform was developed using various microphone types.</li>
                    <li>Electret microphones were tested, requiring amplification but being cost-effective.</li>
                    <li>MEMs microphones, smaller and higher-performance, were interfaced with modern arrays.</li>
                    <li>Two off-the-shelf microphone arrays were tested for performance.</li>
                    <li>The selected six-microphone array could track and record audio for up to four sound sources simultaneously.</li>
                    <li>The final system had a range of 22.5m and angular localization accuracy of ±1.83m.</li>
                </ul>
                <h2 className="font-bold text-blue-800 uppercase text-3xl mb-6 text-center">TLDR</h2>
                <p className="text-xl text-gray-700 leading-relaxed mb-6">
                    A six-microphone array was developed, tracking up to four sound sources with a range of 22.5m and ±1.83m accuracy.
                </p>
            </div>

        </>
    );
}

export default LocalisationHOW;
