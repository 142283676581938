import React, { useState, useEffect } from 'react';
import IntroHOW from '../howitworks/IntroHOW';
import RecognitionHOW from '../howitworks/RecognitionHOW';
import LocalisationHOW from '../howitworks/LocalisationHOW';
import MappingHOW from '../howitworks/MappingHOW';
import Footer from '../components/Footer';

const Howitworks = () => {
    const [showButton, setShowButton] = useState(false);

    // Show button when scrolled 300px
    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 900) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Scroll to top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <IntroHOW />
            <RecognitionHOW />
            <LocalisationHOW />
            <MappingHOW />
            <Footer />

            {showButton && (
                <button
                    onClick={scrollToTop}
                    className="fixed bottom-8 right-8 bg-green-600 hover:bg-green-800 text-white p-3 rounded-full shadow-lg transition-transform duration-300"
                    style={{ zIndex: 1000 }}
                >
                    ↑ Back to Top
                </button>
            )}
        </>
    );
};

export default Howitworks;
